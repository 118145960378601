<template>
  <v-card class="overflow-hidden">
    <div class="d-flex justify-space-between align-center px__15">
      <v-card-title
        class="d-flex justify-center flex-grow-1 font__20 font-weight-bold"
      >
        {{ '검색 필터' | translate }}
      </v-card-title>

      <v-btn
        text
        class="button__Close"
        @click="onClickClose"
      >
        <!--{{ '닫기' | translate }}-->
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>


    <v-card-text
      style="max-height: calc(90vh - 300px)"
      class="overflow-y-auto overflow-x-hidden scroll-custom-area"
    >
      <v-row>
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.useYn"
            :items="useYnOptions"
            :placeholder="'계약상태' | translate"
            item-text="codeValue"
            item-value="codeKey"
            hide-details
            clearable
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.accLevelTypeList"
            :items="accLevelTypeOptions"
            :placeholder="'거래처 등급' | translate"
            item-text="codeValue"
            item-value="codeKey"
            hide-details
            clearable
            multiple
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="userManager.countryFeature('KR')"
        justify="center"
        class="align-center"
      >
        <!-- CMS -->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.cmsState"
            :items="cmsStateOptions"
            item-text="codeValue"
            item-value="codeKey"
            :placeholder="'CMS' | translate"
            hide-details
            clearable
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <!-- 세금계산서 -->
      <v-row
        v-if="userManager.countryFeature('KR')"
        justify="center"
        class="align-center"
      >
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.autoIssueYn"
            :items="autoIssueYnOptions"
            :placeholder="'세금계산서' | translate"
            item-text="codeValue"
            item-value="codeKey"
            hide-details
            clearable
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>

      <v-row>
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.billDayList"
            :items="billDayOnlyNumberOptions"
            :placeholder="'결제일' | translate"
            item-text="codeValue"
            item-value="codeKey"
            multiple
            clearable
            hide-details
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.calculationStartdateList"
            :items="billDayOnlyNumberOptions"
            :placeholder="'계산시작일' | translate"
            item-text="codeValue"
            item-value="codeKey"
            multiple
            clearable
            hide-details
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.unvisitedMonth"
            :items="unvisitedMonthOptions"
            :placeholder="'장기 미방문' | translate"
            item-text="codeValue"
            item-value="codeKey"
            clearable
            hide-details
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="px-0"
        >
          <v-select-backguard
            v-model="state.staffInChargeList"
            :items="workorderStaffOptions"
            :placeholder="'거래처 A/S 담당' | translate"
            item-text="userFullname"
            item-value="userId"
            multiple
            clearable
            hide-details
            class="pa-0 mx-3"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!--      <v-divider />-->
    <v-card-actions
      class="ma-0 mt-2 pa-0"
    >
      <!--        <v-spacer />-->

      <v-btn
        text
        class="button__100per radius__None"
        @click="onClickApply"
      >
        {{ '적용' | translate }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import CodeOptions from "@/components/mixins/CodeOptions";
import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
import VSelectBackguard from "@/components/part/VSelectBackguard";

export default {
  name: "AccountSearchFilterModal",
  components: {VSelectBackguard},
  mixins: [CodeOptions, ModalVuetifyHelper, VuetifyModalBackGuardInterface],
  data() {
    return {
      state: {},
      historyId: undefined,
    };
  },
  computed: {
    /**
     * 필터된 아이템의 이름 배열을 반환합니다.
     */
    filterNameList() {
      const arr = [];
      if (this.userManager.countryFeature('KR')) { // 한국인 경우에만 CMS와 세금계산서 필터가 있습니다.
        // CMS
        if (!this.$isNOU(this.state.cmsState)) {
          const found = this.cmsStateOptions.find(e => this.state.cmsState === e.codeKey);
          if (!!found) arr.push({text: "CMS " + found.codeValue});
        }

        // 세금계산서
        if (!this.$isNOU(this.state.autoIssueYn)) {
          const found = this.autoIssueYnOptions.find(e => this.state.autoIssueYn === e.codeKey);
          if (!!found) arr.push({text: "세금계산서 " + found.codeValue});
        }
      }

      // 계약상태
      if (!this.$isNOU(this.state.useYn)) {
        const found = this.useYnOptions.find(e => this.state.useYn === e.codeKey);
        if (!!found) arr.push({text: found.codeValue});
      }
      // 거래처 등급
      if (!this.$isNOU(this.state.accLevelTypeList)) {
        const filtered = this.accLevelTypeOptions.filter(e => !!this.state.accLevelTypeList.find(c => e.codeKey === c)).map(e => e.codeValue);
        if (!this.$isEmpty(filtered)) arr.push({text: this.$translate("등급") + " " + filtered.join()});
      }

      // 결제일
      if (!this.$isEmpty(this.state.billDayList)) {
        const filtered = this.billDayOnlyNumberOptions.filter(e => !!this.state.billDayList.find(c => e.codeKey === c)).map(e => e.codeValue);
        if (!this.$isEmpty(filtered)) arr.push({text: this.$translate("결제일") + " " + filtered.join()});
      }

      // 계산시작일
      if (!this.$isEmpty(this.state.calculationStartdateList)) {
        const filtered = this.billDayOnlyNumberOptions.filter(e => !!this.state.calculationStartdateList.find(c => e.codeKey === c)).map(e => e.codeValue);
        if (!this.$isEmpty(filtered)) arr.push({text: this.$translate("계산시작일") + " " + filtered.join()});
      }

      // 장기 미방문
      if (!this.$isEmpty(this.state.unvisitedMonth)) {
        const found = this.unvisitedMonthOptions.find(e => this.state.unvisitedMonth === e.codeKey);
        if (!!found) arr.push({text: found.codeValue});
      }

      // 거래처 담당자
      if (!this.$isEmpty(this.state.staffInChargeList)) {
        this.workorderStaffOptions.filter(e => !!this.state.staffInChargeList.find(c => e.userId === c))
          .map(e => e.userFullname).forEach(e => arr.push({text: `담당: ${e}`}));
      }

      return arr;
    },
  },
  mounted() {
    if (this.historyManager.wasRoute(this.historyId) && this.historyManager.isForward) {
      this.state = this.getDefaultState();
    } else {
      this.state = this.getState() || this.getDefaultState();
    }

    this.historyId = this.historyManager.uniqueId; // 히스토리 고유 아이디 저장
    this.loadOptions();
  },
  created() {
    this.state = this.getDefaultState();
  },
  methods: {
    getDefaultState() {
      let obj = {
        // CMS 상태
        cmsState: undefined,
        // 세금계산서
        autoIssueYn: undefined,
        // 계약상태
        useYn: 'Y',
        // 계산시작일
        calculationStartdateList: [],
        // 결제일
        billDayList: [],
        // 장기미방문
        unvisitedMonth: undefined,
        // 거래처 등급
        accLevelTypeList: [],
        // 거래처 담당자
        staffInChargeList: [],
      }

      if (this.propsData.state) {
        obj = {...obj, ...this.propsData.state};
      }

      return obj;
    },

    generateState() {
      let obj = {
        autoIssueYn: this.state.autoIssueYn || null,
        useYn: this.state.useYn || null,
        calculationStartdateList: this.state.calculationStartdateList || [],
        billDayList: this.state.billDayList || [],
        accLevelTypeList: this.state.accLevelTypeList || [],
        staffInChargeList: this.state.staffInChargeList || [],
        unvisitedMonth: this.state.unvisitedMonth || null,
        cmsState: this.state.cmsState || null,
      };

      return obj;
    },

    loadOptions() {
      this.loadAccountLevelCode()
      this.loadCmsStateOptions();
      this.loadAutoIssueYnOptions();
      this.loadUseYnOptions();
      this.loadBillDayOnlyNumberOptions();
      this.loadUnvisitedMonthOptions();
      this.loadOptionsForWorkorderStaff();
    },

    onClickClose() {
      this.close();
    },

    onClickApply() {
      this.setState(this.state);
      this.callAndClose({state: this.generateState(), filterNameList: this.filterNameList});
    },
  }
}
</script>

<!--<style scoped src="./mps-sheet/mps-sheet.css"></style>-->

<style scoped>
>>> .v-dialog {
  overflow: hidden !important;
}

>>> .v-select__selections > input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

>>> .v-select__selections > input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

>>> .v-dialog > .v-card > .v-card__text {
  padding: 0 24px 0 !important;
}

>>> .v-item-group {
  flex: 1;
}

>>> .v-btn-toggle > .v-btn.v-btn {
  flex: 1 !important;
}

>>> .v-btn:not(.v-btn--round).v-size--default {
  min-width: auto;
}


>>> .scroll-custom-area {
  overflow-y: scroll;
}

/* 전체 스크롤바 스타일 */
>>> .scroll-custom-area::-webkit-scrollbar {
  width: 6px !important; /* 스크롤바의 너비 */
}

/* 스크롤바의 트랙 스타일 */
>>> .scroll-custom-area::-webkit-scrollbar-track {
  background: #f1f1f1 !important; /* 트랙의 배경색 */
}

/* 스크롤바의 핸들 스타일 */
>>> .scroll-custom-area::-webkit-scrollbar-thumb {
  background: #0c98fe !important; /* 핸들의 배경색 */
  border-radius: 10px !important; /* 핸들의 모서리를 둥글게 */
}

/* 스크롤바의 핸들에 호버할 때 스타일 */
>>> .scroll-custom-area::-webkit-scrollbar-thumb:hover {
  background: #555 !important; /* 핸들의 배경색 변경 */
}
</style>