<template>
  <v-card class="overflow-hidden">
    <div class="d-flex justify-space-between align-center px__15">
      <v-card-title
        class="d-flex justify-center flex-grow-1 font__20 font-weight-bold"
      >
        {{ '검색 필터' | translate }}
      </v-card-title>

      <v-btn
        text
        class="button__Close"
        @click="onClickClose"
      >
        <!--{{ '닫기' | translate }}-->
        <img src="@/assets/images/main/svg/button_Close.svg">
      </v-btn>
    </div>


    <v-card-text
      style="max-height: calc(90vh - 300px)"
      class="overflow-y-auto overflow-x-hidden scroll-custom-area"
    >
      <v-row
        justify="center"
        class="align-center"
      >
        <!--          <v-col cols="4">-->
        <!--            접수구분-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.printerStateCodes"
            :items="printerStateOptions"
            item-text="codeValue"
            item-value="codeKey"
            :placeholder="'프린터 상태 코드' | translate"
            hide-details
            clearable
            multiple
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="align-center"
      >
        <!--          <v-col cols="4">-->
        <!--            접수구분-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.unlimitedInkYn"
            :items="unlimitedInkYnOptions"
            item-text="codeValue"
            item-value="codeKey"
            :placeholder="'무한잉크_사용여부' | translate"
            hide-details
            clearable
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="align-center"
      >
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.pmChkToner"
            :items="pmChkTonerOptions"
            :placeholder="'토너 사용여부' | translate"
            item-text="codeValue"
            item-value="codeKey"
            clearable
            hide-details
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.pmChkDrum"
            :items="pmChkDrumOptions"
            :placeholder="'드럼 사용여부' | translate"
            item-text="codeValue"
            item-value="codeKey"
            clearable
            hide-details
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <!--          <v-col cols="4">-->
        <!--            진행상태-->
        <!--          </v-col>-->
        <v-col cols="12">
          <v-select-backguard
            v-model="state.isPrinterContract"
            :items="isPrinterContractOptions"
            :placeholder="'계약 여부' | translate"
            item-text="codeValue"
            item-value="codeKey"
            clearable
            hide-details
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div style="width: 100%; display: flex; justify-content: space-between; align-items: center">
            {{ '수집 지연' | translate }}
            <the-mask
              v-model="state.collectDelayStart"
              :placeholder="'0~9999 까지 입력가능' | translate"
              hide-details
              clearable
              :mask="['XXXX']"
              class="pa-2 ma-2"
              style="border-bottom: 1px solid #949494; flex: 1"
            />
            {{ '일 이상' | translate }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div style="width: 100%; display: flex; justify-content: space-between; align-items: center">
            {{ '수집 지연' | translate }}
            <the-mask
              v-model="state.collectDelayEnd"
              :placeholder="'수집 지연 (0~9999 까지 입력가능)' | translate"
              hide-details
              clearable
              :mask="['XXXX']"
              class="pa-2 ma-2"
              style="border-bottom: 1px solid #949494; flex: 1"
            />
            {{ '일 이하' | translate }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <!--      <v-divider />-->
    <v-card-actions
      class="ma-0 mt-2 pa-0"
    >
      <!--        <v-spacer />-->
      <v-btn
        text
        class="button__100per radius__None"
        @click="onClickApply"
      >
        {{ '적용' | translate }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
    import CodeOptions from "@/components/mixins/CodeOptions";
    import ModalVuetifyHelper from "@/assets/plugins/vuetify-modal/ModalVuetifyHelper";
    import VuetifyModalBackGuardInterface from "@/components/vuetify-modal/mixins/VuetifyModalBackGuardInterface";
    import VSelectBackguard from "@/components/part/VSelectBackguard";

    export default {
        name: "PrinterSearchFilterModal",
        components: {VSelectBackguard},
        mixins: [CodeOptions, ModalVuetifyHelper, VuetifyModalBackGuardInterface],
        data() {
            return {
                state: {},
                historyId: undefined,
            };
        },
        computed: {
            _state() {
                return {
                    printerStateCodes: this.state.printerStateCodes, // 프린터 상태 코드
                    unlimitedInkYn: this.state.unlimitedInkYn, // 무한잉크 사용여부
                    pmChkToner: this.state.pmChkToner, // 토너 사용여부
                    pmChkDrum: this.state.pmChkDrum, // 드럼 사용여부
                    isPrinterContract: this.state.isPrinterContract, // 계약 여부
                    isMonitoringPrinter: this.state.isMonitoringPrinter, // 모니터링 여부
                  collectDelayStart: this.state.collectDelayStart,
                  collectDelayEnd: this.state.collectDelayEnd,
                }
            },

            /**
             * 필터된 아이템의 이름 배열을 반환합니다.
             */
            filterNameList() {
                const arr = [];

                // 프린터 상태 코드
                if (!this.$isEmpty(this.state.printerStateCodes)) {
                    this.printerStateOptions.filter(e => !!this.state.printerStateCodes.find(c => e.codeKey === c))
                        .map(e => e.codeValue).forEach(e => arr.push({text: e}));
                }

                // 무한잉크 사용여부
                if (!this.$isNOU(this.state.unlimitedInkYn)) {
                    const found = this.unlimitedInkYnOptions.find(e => this.state.unlimitedInkYn === e.codeKey);
                    if (!!found) arr.push({text: this.$translate("무한잉크") + " " + found.codeValue});
                }

                // 토너 사용여부
                if (!this.$isNOU(this.state.pmChkToner)) {
                    const found = this.pmChkTonerOptions.find(e => this.state.pmChkToner === e.codeKey);
                    if (!!found) arr.push({text: found.codeValue});
                }

                // 드럼 사용여부
                if (!this.$isNOU(this.state.pmChkDrum)) {
                    const found = this.pmChkDrumOptions.find(e => this.state.pmChkDrum === e.codeKey);
                    if (!!found) arr.push({text: found.codeValue});
                }

                // 계약 여부
                if (!this.$isNOU(this.state.isPrinterContract)) {
                    const found = this.isPrinterContractOptions.find(e => this.state.isPrinterContract === e.codeKey);
                    if (!!found) arr.push({text: found.codeValue});
                }

                // 모니터링 여부
                if (!this.$isNOU(this.state.isMonitoringPrinter)) {
                    const found = this.isMonitoringPrinterOptions.find(e => this.state.isMonitoringPrinter === e.codeKey);
                    if (!!found) arr.push({text: found.codeValue});
                }

              if (!this.$isNOU(this.state.collectDelayStart) && this.state.collectDelayStart !== 0 ) {
                arr.push({text: `${this.state.collectDelayStart} 일 이상`})
              }

              if (!this.$isNOU(this.state.collectDelayEnd) && this.state.collectDelayEnd !== 9999) {
                arr.push({text: `${this.state.collectDelayEnd} 일 이하`})
              }

                return arr;
            },
        },
        mounted() {
            if (this.historyManager.wasRoute(this.historyId) && this.historyManager.isForward) {
                this.state = this.getDefaultState();
            } else {
                this.state = this.getState() || this.getDefaultState();
            }

            this.historyId = this.historyManager.uniqueId; // 히스토리 고유 아이디 저장

            this.loadOptions();
        },
        methods: {
            getDefaultState() {
                return {
                    printerStateCodes: [],
                    unlimitedInkYn: null,
                    pmChkToner: null,
                    pmChkDrum: null,
                    isPrinterContract: null,
                    isMonitoringPrinter: null,
                  collectDelayStart: 0,
                  collectDelayEnd: 9999,
                }
            },

            loadOptions() {
                this.loadPrinterStateCodes();
                this.loadUnlimitedInkYnOptions();
                this.loadPmChkTonerOptions();
                this.loadPmChkDrumOptions();
                this.loadIsPrinterContractOptions();
                this.loadIsMonitoringPrinterOptions();
            },

            onClickClose() {
                this.close();
            },

            onClickApply() {
              if (this._state.collectDelayEnd < this._state.collectDelayStart){
                return this.$alert(this.t("수집 지연 일자가 유효하지 않습니다.\n시작일이 종료일보다 큽니다."))
              }
                this.setState(this._state);
                this.callAndClose({state: this._state, filterNameList: this.filterNameList});
            },
        }
    }
</script>

<!--<style scoped src="./mps-sheet/mps-sheet.css"></style>-->

<style scoped>
    >>> .v-dialog {
        overflow: hidden !important;
    }

    >>> .v-select__selections > input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    >>> .v-select__selections > input:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.6);
    }

    >>> .v-dialog > .v-card > .v-card__text {
        padding: 0 24px 0 !important;
    }

    >>> .v-item-group {
        flex: 1;
    }

    >>> .v-btn-toggle > .v-btn.v-btn {
        flex: 1 !important;
    }

    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
    }


    >>> .scroll-custom-area {
      overflow-y: scroll;
    }
    /* 전체 스크롤바 스타일 */
    >>> .scroll-custom-area::-webkit-scrollbar {
      width: 6px !important; /* 스크롤바의 너비 */
    }

    /* 스크롤바의 트랙 스타일 */
    >>> .scroll-custom-area::-webkit-scrollbar-track {
      background: #f1f1f1 !important; /* 트랙의 배경색 */
    }

    /* 스크롤바의 핸들 스타일 */
    >>> .scroll-custom-area::-webkit-scrollbar-thumb {
      background: #0c98fe !important; /* 핸들의 배경색 */
      border-radius: 10px !important; /* 핸들의 모서리를 둥글게 */
    }

    /* 스크롤바의 핸들에 호버할 때 스타일 */
    >>> .scroll-custom-area::-webkit-scrollbar-thumb:hover {
      background: #555 !important; /* 핸들의 배경색 변경 */
    }
</style>
