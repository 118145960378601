<template>
  <v-card
    class="mx-auto boder-bottom"
    flat
    style="border-radius: 0px;"
    width="100%"
  >
    <!--    :color="{'red': item.isReservationIsOver }"-->
    <v-list-item
      v-ripple="false"
      class="text-left"
      style="padding: 14px 18px !important;"
      @click="onDetailClicked(item)"
    >
      <v-list-item-content class="pa-0">
        <v-list-item-title
          class="d-flex justify-space-between align-start"
        >
          <!-- 업체명 -->
          <div
            class="text-left font__14 black--text"
            style="white-space: pre-line; word-break: break-word;"
          >
            [{{ item.raw.accLevelValue }}] {{ item.raw.accountName }} ({{ item.raw.accountId }})
          </div>

          <div
            class="font_16"
            :class="{'color__blue': item.raw.useYn === 'Y', 'color__red': item.raw.useYn !== 'Y'}"
          >
            {{ item.raw.useYnName }}
          </div>
        </v-list-item-title>

        <!-- 주소 -->
        <v-list-item-subtitle class="font__17 black--text font-weight-bold">
          <div style="white-space: pre-wrap !important;">
            <span>{{ item.raw.address }} {{ item.raw.addressDetail }}</span>
          </div>
        </v-list-item-subtitle>

        <!-- 미수내역 -->
        <v-list-item-subtitle v-if="salesConfigPermission &&item.raw.sumBalance > 0">
          <div class="caption d-flex justify-space-between">
            <ul class="pa-0">
              <li
                v-show="item.raw.sumBalance > 0"
                class="color__red font__14"
              >
                {{ '미수금' | translate }} :
                {{ item.raw.outstandingMonths }}{{ '개월' | translate }} /
                {{ item.raw.sumBalance | number }}{{ '원' | translate }}
              </li>
              <li
                v-show="item.raw.sumBalance <= 0"
                style="visibility: hidden;"
              />
            </ul>
          </div>
        </v-list-item-subtitle>

        <!-- 장기미방문 -->
        <v-list-item-subtitle v-if="!!item.raw.unvisitedMonth">
          <div class="caption d-flex justify-space-between">
            <ul class="pa-0">
              <li
                v-show="item.raw.unvisitedMonth > 0"
                class="font__14 d-flex flex-column"
              >
                <span>{{ '장기 미방문' | translate }} : {{ item.raw.unvisitedMonth }} {{ '개월' | translate }}</span>
                <!--                <span>({{ '마지막 방문일' | translate }} : {{ item.raw.unvisitedDate | date }})</span>-->
              </li>
            </ul>
          </div>
        </v-list-item-subtitle>

        <v-list-item-subtitle>
          <v-row
            class="mx-0 d-flex flex-column font__14"
          >
            <span>{{ '임대계약' | translate }} / {{ '해지' | translate }} : {{ item.raw.contractCount | number }} {{ '건' | translate }} / {{ item.raw.cancelContractCount | number }}</span>
            <span>{{ '프린터 계약' | translate }} / {{ '해지' | translate }} : {{ item.raw.printerContractCount | number }} {{ '건' | translate }} / {{ item.raw.cancelPrinterContractCount | number }}</span>
          </v-row>
        </v-list-item-subtitle>

        <v-list-item-subtitle>
          <v-row
            class="mx-0 align-center"
            justify="space-between"
          >
            <div>{{ item.raw.chargeFullname }}</div>
            <context-menu
              :tel-no="item.raw.chargeMobileno"
              :is-account-comp="true"
              :account="item.raw"
              :location="{lat:item.raw.lat, lng:item.raw.lng}"
            />
          </v-row>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
    import ContextMenu from "@/components/widgets/ContextMenu";
    import userManager from "@/assets/plugins/mps-common/user/user-manager";
    import Permissions from "@/constants/permissions";

    export default {
        name: "AccountItemCard",
        components: {ContextMenu},
        props: {
            item: {type: Object, default: undefined},
        },
        computed: {
          salesConfigPermission() {
            return userManager.hasPermission(Permissions.EXPOSE_ACCOUNT_INFO_SALES_CONFIG)
          }
        },
        methods: {
            /**
             * 상세 버튼 클릭시
             * @param work
             */
            onDetailClicked(item) {
                this.$emit("click:item", item)
            },

        }
    }
</script>

<style scoped>
    ul {
        list-style: none;
        text-align: left;
    }

    .v-application .subtitle-2 {
        font-size: 0.875rem;
        font-weight: inherit !important;
        letter-spacing: 0.0071428571em !important;
        line-height: 1.375rem;
        font-family: "Roboto", sans-serif !important;
    }
</style>
