import { render, staticRenderFns } from "./AccountItemCard.vue?vue&type=template&id=10a246fc&scoped=true"
import script from "./AccountItemCard.vue?vue&type=script&lang=js"
export * from "./AccountItemCard.vue?vue&type=script&lang=js"
import style0 from "./AccountItemCard.vue?vue&type=style&index=0&id=10a246fc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10a246fc",
  null
  
)

export default component.exports